@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
  /* Otros formatos de fuente si los tienes disponibles */
}

@font-face {
  font-family: 'Montserrat-Italic';
  src: url('assets/fonts/Montserrat-Italic-VariableFont_wght.ttf') format('truetype');
  /* Otros formatos de fuente si los tienes disponibles */
}

@font-face {
  font-family: 'Bitter';
  src: url('assets/fonts/Bitter-VariableFont_wght.ttf') format('truetype');
  /* Otros formatos de fuente si los tienes disponibles */
}

@font-face {
  font-family: 'Bitter-Italic';
  src: url('assets/fonts/Bitter-Italic-VariableFont_wght.ttf') format('truetype');
  /* Otros formatos de fuente si los tienes disponibles */
}

@font-face {
  font-family: 'Caveat';
  src: url('assets/fonts/Caveat-VariableFont_wght.ttf') format('truetype');
  /* Otros formatos de fuente si los tienes disponibles */
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Bitter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background: #eeedeb;
}

.caveat {
  font-family: 'Caveat', cursive;
}

.caveat-s {
  font-family: 'Caveat', cursive;
  font-size: 10px !important;
}

.caveat-m {
  font-family: 'Caveat', cursive;
  font-size: 30px !important;
}

.caveat-l {
  font-family: 'Caveat', cursive;
  font-size: 50px !important;
}

.caveat-xl {
  font-family: 'Caveat', cursive;
  font-size: 70px !important;
}

.caveat-xxl {
  font-family: 'Caveat', cursive;
  font-size: 90px !important;
}

#root {
  height: 100%;
}

code {
  font-family: 'Montserrat', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*generals*/
.texto-con-espaciado {
  letter-spacing: 2px;
}

/*APP*/
.videoBg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  object-fit: cover;
}

.videoBg-mid {
  width: 100%;
  height: 60%;
  position: absolute;
  top: 0;
  z-index: -1;
  object-fit: cover;
  object-position: 50% 30%;
}

/* Confirmacion.css o tu archivo de estilos */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Color de fondo oscuro para el bloqueo */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* Asegúrate de que esté por encima del resto del contenido */
}

.bgOverlay-mid {
  height: 60%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.07);
  position: absolute;
  top: 0;
  z-index: -1;
}

.no-scroll {
  overflow: hidden;
}

.opacity {
  opacity: 0.7;
}

.none-decoration {
  text-decoration: none;
}

.loader {
  width: 10px;
  height: 10px;
  margin: 40px auto;
  border-radius: 50%;
  background-color: rgb(0, 0, 0);
}

.bgOverlay {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.07);
  position: absolute;
  top: 0;
  z-index: -1;
}

.bgOverlay-before {
  height: 100%;
  width: 100%;
  background-color: rgba(23, 22, 22, 0.87);
  position: absolute;
  top: 0;
  z-index: -2;
}

.bg-gray {
  background-color: #A6A6A636;
}

.layout {
  width: 95%;
  margin: 0 auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.normal-text {
  font-size: 30px;
  color: #462e23b5;
  font-weight: 600;
  margin-bottom: 25px;
  letter-spacing: 3px;
}

.field-value {
  font-size: 20px;
  color: #462e23b5;
  font-weight: 600;
  letter-spacing: 3px;
}

.color-main {
  color: #462e23b5;
}

.field-label {
  font-size: 20px;
  color: #000000a6;
  font-weight: 600;
  letter-spacing: 3px;
}


.medium-text {
  font-size: 2vw;
}



/*Content*/
.content {
  /*min-height: 400px;*/
  flex-grow: 1;
  margin-top: 145px;
}

.page .heading {
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 0px;
  color: rgba(240, 255, 255, 0.514);
  font-size: 55px;
  font-weight: bold;
}

/*inputs*/

input[type="text"],
textarea {
  display: block;
  padding: 15px;
  width: 250px;
  margin-top: 25px;
  padding-bottom: 0px;
  padding-left: 0px;
}

input[type="text"] {
  display: block;
  padding: 15px;
  width: 250px;
  margin-top: 25px;
  padding-left: 0px;
}

input[type="submit"],
button {
  font-size: 18px;
  padding: 15px;
  margin-top: 15px;
  border: 0px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.671);
  background-color: #444;
  border-color: #444;
  text-decoration: none;
  cursor: pointer;
  transition: all 300ms;
  letter-spacing: 2px;
}

input[type="submit"]:hover,
button:hover {
  background-color: rgb(128, 121, 121);
}

/*Projects*/
.page .projects {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grid-container {
  display: flex;
  flex-direction: column;
}

.grid-container .project-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 2px;
  grid-column-gap: 2px;
}

.column-left,
.column-right {
  display: flex;
  flex-direction: column;
}

/*Filter Project*/

.filter {
  display: flex;
  height: 20px;
  justify-content: center;
  align-items: center;
  list-style: none;
  /*border-bottom: 2px solid black;*/
  margin-bottom: 35px;
}


.filter nav {
  flex-grow: 1;
}

.filter nav ul {
  height: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  padding: 0;
  gap: 2vw;
}

.filter li {
  /*text-transform: uppercase;*/
  font-weight: 400;
  font-size: 12px;
  margin-left: 15px;
  margin-right: 15px;
  letter-spacing: 0px;
  font-stretch: ultra-expanded;
  font-weight: 600;

}

.filter li a {
  text-decoration: none;
  transition: all 300ms;
  color: #828282;
}

.filter .active,
.filter a:hover {
  /*color: rgba(172, 170, 170, 0.692);*/
  font-weight: 600;
  color: rgb(0, 0, 0);
}

/*Project Item*/
/*Project Item*/
.project-item {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.project-item .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  opacity: 0;
  transition: opacity 0.3s ease;
}

.project-item-content {
  height: 100%;
}

.project-item:hover .overlay {
  opacity: 1;
}

.project-item .mask {
  width: 100%;
  overflow: hidden;
  height: 100%;
  /* Ajusta la altura automáticamente según el contenido (imagen) */
  /*
  border: 1px solid rgb(2, 2, 2);
  box-shadow: 0px 5px 14px rgb(2, 2, 2);*/
  position: relative;
  overflow: hidden;
}

.project-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ajusta el ajuste del contenido de la imagen */
  aspect-ratio: 3 / 1.8;
}

.project-item .project-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.project-item .project-name {
  align-self: flex-start;
  position: absolute;
  display: block;
  font-size: 2.3vh;
  color: aliceblue;
  margin-top: 5px;
  font-weight: bold;
  letter-spacing: 3px;
  margin-left: 15px;
  margin-top: 15px;
}

.project-item .project-name div {
  /* Ajusta el ancho del div del nombre según tus necesidades */
  word-wrap: break-word;
}

.project-process {
  align-self: flex-start;
  position: absolute;


  color: rgba(132, 133, 134, 0.486);
  margin-top: 5px;
  font-weight: bold;
  letter-spacing: 3px;
  margin-left: 15px;
  margin-top: 15px;
  align-content: center;
  justify-content: center;
  display: flex;
  width: 90vw;
}

.project-item .project-category {
  font-size: 11px;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  transform: rotate(1deg);
  transform-origin: top left;
}

.project-item span {
  display: block;
  color: gray;
}

.project-item a {
  color: rgba(253, 252, 252, 0.966);
  text-decoration: none;
  transition: all 300ms;
  width: 80%;
  display: inline-block;
}

/*
.project-item{
  flex-basis: 10%;
  flex-grow: 1;
  max-width: 30%;
  margin: 10px;
}

.project-item .mask{
  width: 100%;
  height: 100px;
  overflow: hidden;
  border: 1px solid rgb(2, 2, 2);
  box-shadow: 0px 5px 14px rgb(2, 2, 2);
}

.project-item img{
  width: 100%;
  height: auto;
  transition: all 500ms;
}

.project-item .mask:hover img{
  width: 150%;
  margin-right: 30%;
}

.project-item span{
  display: block;
  color: gray;
  margin-top: 35px;
}

.project-item h2{
  display: block;
  font-size: 30px;
  color: aliceblue;
  margin-top: 5px;
  font-weight: lighter;
}

.project-item a{
  color: black;
  text-decoration: none;
  transition: all 300ms;
}

.project-item a:hover{
  color:  brown;
}*/
.page-projects {
  position: relative;
  min-height: 100vh;
}

.page-project .mask {
  height: 300px;
  overflow: hidden;
}

/* Confirmacion.css o tu archivo de estilos */
.radio-container,
.bottom-radio {
  display: flex;
  align-items: center;
}

.radio-label {
  margin-right: 10px;
}

.font-red {
  color: rgba(197, 14, 14, 0.678);

}

.radio-input {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #462e23b5;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  margin-right: 5px;
}

.radio-input:checked {
  background-color: #462e23b5;
  border: 2px solid #462e23b5;
}

/*RESPONSIVE*/
@media (max-width: 966px) {

  /*Projects*/
  .grid-container {
    display: flex;
  }

  .grid-container .project-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
  }

  .project-item {
    width: 100%;
  }

  .project-item .project-name {
    width: 70%;
    font-size: 14px;
  }

  .project-item .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #2b272726;
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  /*filter*/
  .filter {
    display: flex;
    height: 20px;
    justify-content: center;
    align-items: center;
    list-style: none;
    /*border-bottom: 2px solid black;*/
    margin-bottom: 20px;
  }

  .filter nav ul {
    height: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    padding: 0;
    gap: 0vw;
    flex-wrap: wrap;
  }

  .filter li {
    /*text-transform: uppercase;*/
    font-weight: 400;
    font-size: 10px;
    margin-left: 0px;
    margin-right: 18px;
    letter-spacing: 0px;
    font-stretch: ultra-expanded;
    font-weight: 600;

  }

  .normal-text {
    font-size: 4vw;
    margin-bottom: 15px;
  }

  .medium-text {
    font-size: 20px;
  }

  .field-value {
    font-size: 15px;
    color: #462e23b5;
    font-weight: 600;
    letter-spacing: 3px;
    padding-left: 0;
  }

  .field-label {
    font-size: 15px;
    color: #000000a6;
    font-weight: 600;
    letter-spacing: 3px;

  }

  /*Content*/
  .content {
    /*min-height: 400px;*/
    flex-grow: 1;
    margin-top: 145px;
  }

  .videoBg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
    object-fit: cover;
    object-fit: cover;
    object-position: 80% 30%;
  }

  .caveat-s {
    font-family: 'Caveat', cursive;
    font-size: 5px !important;
  }

  .caveat-m {
    font-family: 'Caveat', cursive;
    font-size: 10px !important;
  }

  .caveat-l {
    font-family: 'Caveat', cursive;
    font-size: 20px !important;
  }

  .caveat-xl {
    font-family: 'Caveat', cursive;
    font-size: 30px !important;
  }

  .caveat-xxl {
    font-family: 'Caveat', cursive;
    font-size: 40px !important;
  }

}