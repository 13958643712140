/*Header*/
.nav-bar {
  position: fixed;
  width: 100%;
  background: transparent;
  border-bottom: 1px solid transparent;
  transition: all 0.9s ease;
  transition-property: all;
  transition-duration: 0.9s;
  transition-timing-function: ease;
  transition-delay: 0s;
  transform-origin: 0 0;
  z-index: 1000;
  height: 110px;
  top: 0;
  left: 0;
}

.nav-mode-one {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background: white;
  height: 110px;
  border-bottom: 1px solid #ebebeb;
}

.dark {
  background: #1f1211;
  border-bottom: 1px solid #1f1211;
}

.header {
  display: flex;
  height: 110px;
  justify-content: center;
  align-items: center;
  list-style: none;
  /*border-bottom: 2px solid black;*/
  margin-bottom: 35px;
}

.header .logo {
  flex-grow: 1;
  /*position: absolute;*/
  /*left: 0;*/
  margin-left: 2.5%;
}

.header .logo img {
  width: calc(4450px / 25);
  height: calc(1844px / 25);
}

.header .logo span {
  display: block;
  font-size: 90px;
  font-weight: lighter;
  margin-top: -8px;
  color: white;
}

.header .logo h3 {
  display: block;
  flex-basis: 40px;
  margin-left: 10px;
  font-weight: lighter;
  text-transform: uppercase;
  color: white;
}

.header nav {
  flex-grow: 1;
}

.header nav ul {
  height: 132px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
}

.header .menu-icon {
  display: none;
}

.header li {
  /*text-transform: uppercase;*/
  font-weight: 500;
  font-size: 15px;
  margin-left: 15px;
  margin-right: 15px;
  letter-spacing: 5px;
  font-stretch: ultra-expanded;
}

.header li a {
  text-decoration: none;
  transition: all 300ms;
  opacity: 0.6;
}

.header a {
  text-decoration: none;
  transition: all 300ms;
  opacity: 0.8;
}

.header .font-dark {
  color: rgb(0, 0, 0);
}

.header .font-light {
  color: rgb(255, 255, 255);
}

.header .logo-font-dark {
  color: rgb(0, 0, 0);
}

.header .active,
.header a:hover {
  /*color: rgba(172, 170, 170, 0.692);*/
  font-weight: 500;
  opacity: 1;
}

.header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: white;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

.header .nav2-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: white;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}


/*RESPONSIVE*/
@media (max-width: 966px) {
  .header .menu-icon {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 0;
  }

  .header .nav-btn {
    position: absolute;
    right: 0;
    visibility: visible;
    opacity: 1;
  }

  .header .nav2-btn {
    position: absolute;
    right: 0;
    visibility: visible;
    opacity: 1;
  }

  .header .responsive_nav {
    transform: translateY(100vh);
  }

  .header .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 25px;
  }


  .header nav {
    width: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .header nav.active {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(31, 31, 31, 0.99);
    /* Fondo blanco transparentoso */
    z-index: 999;
  }

  .header nav ul {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
  }

  .header nav ul li {
    margin-right: unset;
    margin-top: 22px;
    color: white;
    font-size: 2vh;
  }

  .header .font-dark {
    color: #f2f2f2;
  }

  .header .logo-font-dark {
    color: rgb(0, 0, 0);
  }
}