.main-page {
  position: relative;
  min-height: 100vh;
}

.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.background-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.heading {
  position: fixed;
  top: 50px;
  left: 50px;
  z-index: 1;
  color: #fff;
}

.hide {
  opacity: 0;
}

.content-project {

  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.show {
  opacity: 1;
}

.title-project {
  align-items: center;
  display: flex;
  height: 80vh;
  margin-left: 0vw;
  margin-bottom: 8px;
  width: 100%;
  color: #ffffff;
  margin-top: 0px;
  justify-content: center;
}

.page-project .font-title {
  white-space: pre-line;
  font-weight: 100;
  font-size: 80px;
  margin-bottom: 0;
  margin-top: 0;
}

.darks {
  color: #1f1211;
  margin: 0;
}

.font-title .lights {
  color: #ffffff;
}

.page-project {
  align-items: center;
  display: flex;

  flex-direction: column;
}

/*Fa down*/
.page-project .fa {
  font-size: 60px;
  color: rgba(255, 255, 255, 0.705);
  font-weight: 100;
  align-items: center;
  justify-content: center;
  display: flex;
}

.box-animate {
  position: relative;
  left: -3%;
  animation-name: pippo;
  animation-duration: 0.5s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.btn-scroll-down:hover {
  cursor: pointer;
}

.btn-scroll-down {
  height: 10%;
}

@keyframes pippo {
  0% {
    top: -20%
  }

  100% {
    top: -5%
  }
}

/*Project Description*/
.project-description {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-direction: column;
}

.center-text {
  text-align: center;
  justify-content: center;
  display: flex;
}

.project-description .title {
  flex-basis: 40%;
  /* Cada elemento ocupa el 50% del espacio vertical disponible */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0px;
}

.normal-text {
  font-size: 30px;
  color: #462e23b5;
  font-weight: 600;
  margin-bottom: 25px;
}

.project-description .information {
  display: flex;
  margin: auto;
  flex-wrap: wrap;
  flex-basis: 60%;
  /* Cada elemento ocupa el 50% del espacio vertical disponible */
}

.project-description .information .data {
  display: flex;
  flex-direction: row;
  margin-left: 10%;
  margin-right: 0px;
  margin-top: 0px;
  flex-basis: 80%;
  color: #462e23b5;
  font-size: 2.0vh;
  justify-content: center;
  margin-bottom: 10px;
  height: 30px;
}

.project-description .information .data .count {
  font-size: 3.0vh;
}

.message-bienvenida {
  color: #1f1211;
  width: 60%;
  margin: auto;
  margin-top: -10px;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  letter-spacing: 1px;
}

.message-bienvenida p {
  font-weight: 400;
}

.button-confirmation {
  margin-top: 20px;
}

.project-description .message-title {
  font-size: 38px;
}

/*RESPONSIVE*/
@media (max-width: 966px) {
  .page-project .title-project {
    font-size: 5vh;
    font-weight: 100;
    margin-bottom: 8px;
    color: #ffffff;
  }

  .page-project .font-title {
    white-space: pre-line;
    font-weight: 100;
    font-size: 60px;
    margin-bottom: 0;
    margin-top: 0;
    height: 90%;
  }

  .page-project .project-description {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    margin-bottom: 90px;
  }

  .project-description .information .data {
    display: flex;
    flex-direction: row;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    flex-basis: 100%;
    box-sizing: border-box;
  }

  .project-description .title {
    flex-basis: 30%;
    /* Cada elemento ocupa el 50% del espacio vertical disponible */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .project-description .title {
    padding-left: 15px;
  }

  .normal-text {
    font-size: 4vw;
    margin-bottom: 15px;
  }

  .higth-text {
    font-size: 10vw;
  }

  /*Content*/
  .content {
    /*min-height: 400px;*/
    flex-grow: 1;
    margin-top: 0;
    height: 100%;
  }

  .page-project {
    align-items: center;
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  .main-page {
    position: relative;
    min-height: 100% !important;
    height: 100%;

  }

  .message-bienvenida {
    color: #1f1211;
    width: 98%;
    margin: auto;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    font-size: 15px;
  }

  .button-confirmation {
    margin-top: 0px;
  }

  .project-description .message-title {
    font-size: 28px;
  }
}