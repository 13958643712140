.sitio-description {
  display: flex;
  margin-bottom: 10px;
  margin-top: 0px;
  width: 90%;
  flex-direction: column;
}


/* Confirmacion.css o tu archivo de estilos */
.loading-overlay-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Color de fondo oscuro para el bloqueo */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  /* Asegúrate de que esté por encima del resto del contenido */
}

.location-info {
  text-align: justify;
}

.message-title {
  font-size: 38px;
}

.loading-spinner-map {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #462e23b5;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  z-index: 1000;
}

/*RESPONSIVE*/
@media (max-width: 966px) {
  .page-sitio .title-sitio {
    font-size: 5vh;
    font-weight: 600;
    margin-bottom: 8px;
    color: #ffffff;
  }

  .sitio-description {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-top: 120px;
  }

  .sitio-description .information .data {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 30px;
    flex-basis: 20%;
  }

  .message-title {
    font-size: 28px;
  }
}