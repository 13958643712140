/* Cronograma.css */

.cronograma-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  margin-top: 0px;
}

.cronograma-titulo {
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 0px;
}

.evento {
  margin-bottom: 40px;
}

.hora {
  font-size: 20px;
}

.titulo-evento {
  font-size: 18px;
  margin-bottom: 10px;
}

.descripcion-evento {
  font-size: 14px;
}

.linea-divisoria {
  width: 80%;
  margin: 0 auto;
  border-top: 1px solid #ccc;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .linea-divisoria {
    width: 100%;
  }

  .cronograma-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    margin-top: 120px;
  }
}