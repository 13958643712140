.page {
  opacity: 1;
  justify-content: center;
  display: flex;
  height: 100%;
}

.confirmacion {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  gap: 40px;
}

.confirmacion-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

}


.confirmacion .heading {
  color: rgba(255, 255, 255, 0.59);
  position: absolute;
  top: 0px;
  font-size: 10vw;
  left: auto;
  z-index: -1;
}

.bottom-radio {
  margin-bottom: 8px;
  margin-top: 7;
}

.confirmacion .confirmacion-image {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
}

.confirmacion-image img {
  height: 100%;
}

.confirmacion-container .section-information {
  background-color: #f5f5f5;
  width: 80%;
  height: auto;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 15px;
}

.confirmation-process {
  width: 80%;
  margin: auto;
}

.radio-confirmation {
  width: 100%;
}

.confirmacion .confirmacion-form {
  display: flex;
  flex-wrap: wrap;
}

.confirmacion-form .input-form {
  flex-basis: 50%;
  box-sizing: border-box;
  max-width: 100%;
  /* Tamaño máximo del ancho del elemento */
  max-height: 100%;
  margin-right: 10px;
  margin-bottom: 25px;
}

.confirmacion-form .textarea-form {
  flex-basis: 100%;
  max-width: calc(100% - 20px);
}

.confirmacion .confirmacion-sub {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
  color: #1f1211;
}

input[type="text"],
textarea {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  background: transparent;
  resize: none;
  width: calc(90%);
  margin-top: 0px
}

textarea {
  width: 92%
}

input[type="text"]:focus,
textarea:focus {
  box-shadow: 0 1px 0 black;
}

.confirmacion label {
  font-weight: 500;
  color: #1f1211;
}

.title-welcome {
  width: 80%;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.normal-parrafo {
  font-size: 15px;
  margin-bottom: 15px;
  color: #1f1211;
  font-weight: 500;
  letter-spacing: 5px;
}


.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #462e23b5;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.error-message {
  color: #ff0000ab;
  font-size: 14px;
  margin-top: 5px;
  position: absolute;
  font-weight: 600;
}

.confirmation-message {
  color: #1f1211;
  font-size: 14px;
  margin-top: 5px;
  position: fixed;
  font-weight: 600;

}

/*RESPONSIVE*/
@media (max-width: 966px) {
  .confirmacion {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 80%;
    gap: 40px;
  }

  .confirmacion .confirmacion-image {
    flex-basis: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .confirmacion .heading {
    color: rgba(255, 255, 255, 0.59);
    position: absolute;
    top: 50%;
    font-size: 10vh;
    left: 30%;
    z-index: -1;
    transform: rotate(-70deg);
    /* Rotate the text vertically */
    transform-origin: left top;
  }

  .confirmacion-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
  }

  .confirmacion-container .section-information {
    background-color: #f5f5f5;
    width: 90%;
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 300px;
  }

  .confirmation-process {
    width: 90%;
    margin: auto;
  }

  .normal-text {
    font-size: 4vw;
    margin-bottom: 15px;
  }

  .title-welcome {
    width: 90%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}